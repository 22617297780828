import React, { useState, useLayoutEffect, useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { StaticImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import Flickity from 'react-flickity-component'

import Styleswrapper from '../../components/StylesWrapper';
import PageMeta from '../../components/blocks/helmet/PageMeta';
import Mainheader from '../../components/MainHeader';
import Footer from '../../components/Footer';
import '../../styles/work/detail.css';
import '../../styles/work/connektion/index.css';
import '../../styles/plugins/flickity.css'

// images
import KnotIcon from '../../images/icons/knot.png';
import RadioactiveIcon from '../../images/icons/radioactive.png';
import ControlKnobsIcon from '../../images/icons/control_knobs.png';
import SparkleIcon from '../../images/icons/sparkle.png';
import CactusIcon from '../../images/icons/cactus.png';
import TelephoneReceiverIcon from '../../images/icons/telephone_receiver.png';
import LogIcon from '../../images/icons/log.png';
import KeyboardIcon from '../../images/icons/keyboard.png';

// gallery one videos
import JobsFlowVideo from '../../images/work/connektion/jobs_flow.mp4';
import PrimaryNavigationVideo from '../../images/work/connektion/primary_navigation.mp4';
import ResumeFlowVideo from '../../images/work/connektion/resume_flow.mp4';

// gallery two videos
import DashboardAndJobsFlowVideo from '../../images/work/connektion/dashboard_and_jobs_flow.mp4';
import EventsAndRequestsFlowVideo from '../../images/work/connektion/events_and_requests_flow.mp4';

import WorkOutro from '../../components/work/WorkOutro';

const Connektion = () => {
    gsap.registerPlugin(ScrollTrigger);

    const [scrollProgress, setScrollProgress] = useState(0);

    useLayoutEffect(() => {
        let progress = 0;
        let progressTl = gsap.timeline({});
        let masterTl = gsap.timeline({});

        progressTl.to(progress, {
            progress: 1,
            duration: 1,
            ease: "none",
            scrollTrigger: {
                start: "top top",
                trigger: ".wd-wrapper",
                endTrigger: outroRef.current,
                end: "top bottom",
                invalidateOnRefresh: true,
                scrub: true,
                onUpdate: (self) => setScrollProgress(self.progress.toFixed(2)),
            }
        });

        masterTl.add(progressTl);

        // cleanup
        return () => {
            masterTl.kill();
        };
    }, []);

    const workProgressBar = useRef();
    const progressTextSource = "Creating a digital instrument";
    const progressTextArray = progressTextSource.split("");
    const progressLength = progressTextArray.length;

    const getSplitText = (percentage) => {
        let progress = progressTextArray.slice(0, progressLength * percentage);
        let pending = progressTextArray.slice(progressLength * percentage, progressLength);
        return [progress.join(""), pending.join("")];
    }

    let fadeVariants = {
        initial: {
            opacity: 0,
            y: "2%",
        },
        animate: {
            opacity: 1,
            y: "0%",
            transition: {
                duration: 0.4,
                ease: "circOut",
            }
        }
    };

    const analysisRef = useRef();
    const designRef = useRef();
    const unicoRef = useRef();
    const outroRef = useRef();

    // hide progressbar when outro is in view
    useLayoutEffect(() => {
        let animation = gsap.to(workProgressBar.current, {
            opacity: 0,
            ease: "power4.out",
            scrollTrigger: {
                trigger: outroRef.current,
                scrub: 0.25,
                end: "top 90%"
            }
        });

        // cleanup
        return () => {
            animation.kill();
        };
    }, []);

    // gallery one
    const galleryOneRef = useRef(null);
    const [galleryOneIndex, setGalleryOneIndex] = useState(0);
    const galleryOneCaptions = [
        "Jobs flow",
        "Primary navigation",
        "Resume flow"
    ];

    // gallery two
    const galleryTwoRef = useRef(null);
    const [galleryTwoIndex, setGalleryTwoIndex] = useState(0);
    const galleryTwoCaptions = [
        "Dashboard and Jobs flow",
        "Events and Requests flow",
    ];

    const galleryOptions = {
        autoPlay: false,
        prevNextButtons: false,
        pageDots: false,
        draggable: false,
        setGallerySize: false,
        wrapAround: true,
        selectedAttraction: 0.1,
        friction: 0.8,
    };

    // set current gallery index
    useEffect(() => {
        galleryOneRef.current.on('change', () => {
            setGalleryOneIndex(galleryOneRef.current.selectedIndex);
        });

        galleryTwoRef.current.on('change', () => {
            setGalleryTwoIndex(galleryTwoRef.current.selectedIndex);
        });

        return () => {
            galleryOneRef.current.off('change');
            galleryOneRef.current.destroy();

            galleryTwoRef.current.off('change');
            galleryTwoRef.current.destroy();
        }
    }, []);

    // pause video playback when not in view
    useEffect(() => {
        let observerOptions = {
            rootMargin: "0px",
            threshold: [0.25, 0.75] // start playing when 25% and 75% of the element is in view
        };

        // watch all selected videos and control playback when they enter the viewport
        let handlePlay = (entries, videoObserver) => {
            entries.forEach((entry) => {
                let videoElement = entry.target;
                if (entry.isIntersecting) {
                    videoElement.play();
                } else {
                    videoElement.pause();
                }
            });
        };

        let videoObserver = new IntersectionObserver(handlePlay, observerOptions);

        // select all the videos on page and observe them
        let videos = document.querySelectorAll("video");
        videos.forEach((video) => {
            videoObserver.observe(video);
        });

        // cleanup
        return () => {
            videoObserver.disconnect();
        }
    }, []);

    return (
        <Styleswrapper
            bgColor1="#082928"
            bgColor2="#00070D"
            bg1Stop="0%"
            bg2Stop="35%"
            noise="true"
        >
            <PageMeta
                theme="#082928"
                title="Connektion — Nihar Bhagat"
                description="Design a SaaS platform that helps students get their first job"
                image="../images/social/work/connektion/default.jpg"
                twitterImage="../images/social/work/connektion/twitter.jpg"
            />

            <Mainheader
                bgColor1="#082928"
                bgColor2="#00070D"
                bg1Stop="0%"
                bg2Stop="35%"
                currentPage={'work'}
                noise="true"
            />

            <article className="wd-wrapper connektion">

                {/* I  N  T  R  O */}
                <section className="wd-block intro">
                    <div className="first-column">
                        <motion.div
                            className="wd-cc"
                            variants={fadeVariants}
                            initial="initial"
                            animate="animate"
                        >
                            <div className="title">
                                <h2>Introduction</h2>
                                <img src={KnotIcon}
                                    alt="🪢"
                                    className="icon"
                                    loading="eager"
                                    draggable="false" />
                            </div>
                            <div className="copy">
                                <p className="highlight">
                                    Have you ever seen the products designed by Dieter Rams?
                                </p>
                                <span className="divider"></span>
                                <p>
                                    He has made long-lasting products which are pleasing to look at and very intuitive. It is as if we always knew how the things he designs work.
                                </p>
                            </div>
                        </motion.div>

                        <motion.div
                            className="wd-cc"
                            variants={fadeVariants}
                            initial="initial"
                            animate="animate"
                        >
                            <div className="copy">
                                <p className="highlight">
                                    With this quality as the North Star, I set out to design software for Connektion — a platform that helps students get their first job.
                                </p>
                                <span className="divider"></span>
                                <p>
                                    To do this, I created an interface for the students that is a visual treat. It is made like an instrument to make them employable and get their first job. I also created behind-the-scenes machinery (modules) for Universities and Companies.
                                </p>
                            </div>
                        </motion.div>
                    </div>

                    <div className="second-column">
                        <div className="wd-media-wrapper">
                            <motion.div
                                className="wd-media"
                                variants={fadeVariants}
                                initial="initial"
                                animate="animate"
                            >
                                <StaticImage
                                    src="../../images/work/connektion/1.jpg"
                                    width={1800}
                                    alt="All the papers involved in the connektion project"
                                    className="wd-media-img"
                                    loading="lazy"
                                    draggable="false"
                                    placeholder="blurred"
                                />
                            </motion.div>
                        </div>

                        <div className="wd-media-wrapper">
                            <motion.div
                                className="wd-media"
                                variants={fadeVariants}
                                initial="initial"
                                animate="animate"
                            >
                                <StaticImage
                                    src="../../images/work/connektion/1b.jpg"
                                    width={600}
                                    alt="Braun TP1 by Dieter Rams (1959)"
                                    className="wd-media-img"
                                    loading="lazy"
                                    draggable="false"
                                    placeholder="blurred"
                                />

                                <div className="wd-media-caption">
                                    <span>Braun TP1 by Dieter Rams (1959)</span>
                                    <img src={RadioactiveIcon}
                                        alt="☢️"
                                        loading="lazy"
                                        draggable="false"
                                        className="icon"
                                    />
                                </div>
                            </motion.div>
                        </div>

                        <motion.div
                            className="wd-cc wd-cc-emoji"
                            variants={fadeVariants}
                            initial="initial"
                            animate="animate"
                        >
                            <div className="title">
                                <img src={ControlKnobsIcon}
                                    alt="🎛️"
                                    className="icon"
                                    loading="eager"
                                    draggable="false" />
                            </div>
                            <div className="copy">
                                <p>
                                    I undertook this project as an independent contractor taking 6 months from Research to the final Engineering Handoff.
                                    <br /><br />
                                    The following process was possible because of a client that valued good design and also had the painstaking patience required for its production.
                                </p>
                            </div>
                        </motion.div>
                    </div>
                </section>

                {/* A  N  A  L  Y  S  I  S */}
                <section className="wd-block analysis" ref={analysisRef}>
                    <div className="first-column">
                        <motion.div
                            className="wd-cc"
                            variants={fadeVariants}
                            initial="initial"
                            whileInView="animate"
                            viewport={{
                                once: true,
                                root: analysisRef.current,
                                margin: "0% 0% -10% 0%",
                            }}
                        >
                            <div className="title">
                                <h2>Lay of the land</h2>
                                <img src={CactusIcon}
                                    alt="🌵"
                                    className="icon"
                                    loading="eager"
                                    draggable="false" />
                            </div>
                            <div className="copy">
                                <p className="highlight">
                                    Upon analyzing the data I discovered some silent assumptions for the main user — the student.
                                </p>
                                <p>
                                    <span className="divider"></span>
                                    The client had provided me with existing reports, a feature list, and their time for discussions around the product and vision.
                                </p>
                            </div>
                        </motion.div>

                        <motion.div
                            className="wd-cc"
                            variants={fadeVariants}
                            initial="initial"
                            whileInView="animate"
                            viewport={{
                                once: true,
                                root: analysisRef.current,
                                margin: "0% 0% -10% 0%",
                            }}
                        >
                            <div className="copy">
                                <p className="highlight">
                                    To test these out, I conducted interviews with 20 recent-graduates across diverse fields and interests.
                                </p>
                                <p>
                                    <span className="divider"></span>
                                    I learnt about the forces that act on the students from when they enter college till they get their first job, defined perceptual dimensions, and discovered key pivot points for students.
                                </p>
                            </div>
                        </motion.div>
                    </div>
                    <div className="second-column">
                        <div className="wd-media-wrapper">
                            <motion.div
                                className="wd-media"
                                variants={fadeVariants}
                                initial="initial"
                                whileInView="animate"
                                viewport={{
                                    once: true,
                                    root: analysisRef.current,
                                    margin: "0% 0% -10% 0%",
                                }}
                            >
                                <StaticImage
                                    src="../../images/work/connektion/2.jpg"
                                    height={1760}
                                    width={900}
                                    alt="Notes from interviews with graduates"
                                    className="wd-media-img"
                                    loading="lazy"
                                    draggable="false"
                                    placeholder="blurred"
                                />
                                <div className="wd-media-caption">
                                    <span>Notes from the interviews</span>
                                    <img src={TelephoneReceiverIcon}
                                        alt="📞"
                                        loading="lazy"
                                        draggable="false"
                                        className="icon"
                                    />
                                </div>
                            </motion.div>
                        </div>
                    </div>
                    <div className="third-column">
                        <motion.div
                            className="wd-cc wd-cc-emoji"
                            variants={fadeVariants}
                            initial="initial"
                            whileInView="animate"
                            viewport={{
                                once: true,
                                root: analysisRef.current,
                                margin: "0% 0% -10% 0%",
                            }}
                        >
                            <div className="title">
                                <img src={SparkleIcon}
                                    alt="❇️"
                                    className="icon"
                                    loading="eager"
                                    draggable="false" />
                            </div>
                            <div className="copy">
                                <p>
                                    There was an anomaly staring right at me.
                                    <br /><br />
                                    I saw that in the seemingly similar stories of these students, there is an interaction that expands their awareness. It could be a field trip or a conversation with a senior but the interaction illuminates the jungle out there.
                                </p>
                            </div>
                        </motion.div>

                        <motion.div
                            className="wd-cc"
                            variants={fadeVariants}
                            initial="initial"
                            whileInView="animate"
                            viewport={{
                                once: true,
                                root: analysisRef.current,
                                margin: "0% 0% -10% 0%",
                            }}
                        >
                            <div className="copy">
                                <p className="highlight">
                                    Away from the saturation of job portals, there was a gap no one was looking at.
                                </p>
                                <p>
                                    <span className="divider"></span>
                                    If this platform could be the cause of such interventions, of enlightening the student about how the jungle is, what traps to avoid, it could change culture. We discovered a unique offering. It amplified the existing vision of Connektion.
                                </p>
                            </div>
                        </motion.div>
                    </div>
                </section>

                {/* D  E  S  I  G  N */}
                <section className="wd-block design" ref={designRef}>
                    <div className="first-column">
                        <motion.div
                            className="wd-cc"
                            variants={fadeVariants}
                            initial="initial"
                            whileInView="animate"
                            viewport={{
                                once: true,
                                root: designRef.current,
                                margin: "0% 0% -10% 0%",
                            }}
                        >
                            <div className="title">
                                <h2>Design</h2>
                                <img src={LogIcon}
                                    alt="🪵"
                                    className="icon"
                                    loading="eager"
                                    draggable="false" />
                            </div>
                            <div className="copy">
                                <p className="highlight">
                                    To effectively convey this, I created a pitch leading to the insight, outlining the steps to achieve that.
                                </p>
                                <span className="divider"></span>
                                <p>
                                    The client saw what I saw and we aligned the features with the new insight in the next few days. For clarity on the structure and flow, I drew up low fidelity wireframes for better communication.
                                </p>
                            </div>
                        </motion.div>

                        <motion.div
                            className="wd-cc"
                            variants={fadeVariants}
                            initial="initial"
                            whileInView="animate"
                            viewport={{
                                once: true,
                                root: designRef.current,
                                margin: "0% 0% -10% 0%",
                            }}
                        >
                            <div className="copy">
                                <p className="highlight">
                                    Resisting the delicious temptation to organize the features into obvious categories, I began work at the heart of this platform: Jobs.
                                </p>
                                <span className="divider"></span>
                            </div>
                        </motion.div>
                    </div>

                    <div className="second-column">
                        <div className="wd-media-wrapper">
                            <motion.div
                                className="wd-media"
                                variants={fadeVariants}
                                initial="initial"
                                whileInView="animate"
                                viewport={{
                                    once: true,
                                    root: designRef.current,
                                    margin: "0% 0% -10% 0%",
                                }}
                            >
                                <StaticImage
                                    src="../../images/work/connektion/3.jpg"
                                    width={1800}
                                    alt="Pitch outline"
                                    className="wd-media-img"
                                    loading="lazy"
                                    draggable="false"
                                    placeholder="blurred"
                                    transformOptions={{
                                        cropFocus: "north",
                                    }}
                                />
                            </motion.div>
                        </div>


                    </div>

                    <div className="third-column">
                        <div className="wd-media-wrapper">
                            <motion.div
                                className="wd-media"
                                variants={fadeVariants}
                                initial="initial"
                                whileInView="animate"
                                viewport={{
                                    once: true,
                                    amount: 0.2,
                                }}
                            >
                                <StaticImage
                                    src="../../images/work/connektion/3b.jpg"
                                    width={450}
                                    alt="The Job chip widget"
                                    className="wd-media-img"
                                    loading="lazy"
                                    draggable="false"
                                    placeholder="blurred"
                                />
                            </motion.div>
                        </div>
                        <motion.div
                            className="wd-cc"
                            variants={fadeVariants}
                            initial="initial"
                            whileInView="animate"
                            viewport={{
                                once: true,
                                root: designRef.current,
                                margin: "0% 0% -10% 0%",
                            }}
                        >
                            <div className="copy">
                                <p className="highlight">
                                    I designed the first widget: The Job Chip which had a two-line structure and easy to spot job-status indicators.
                                </p>
                                <p>
                                    <span className="divider"></span>
                                    Piece-by-piece the entire Job Centre took form through such utilities. As if the Law of Compounding had taken a note of us, I created the rest of the Centres in 1/4th time per Centre. Consequentially, the primary navigation took form.
                                </p>
                            </div>
                        </motion.div>
                    </div>
                </section>

                {/* G  A  L  L  E  R  Y    O  N  E */}
                <motion.section
                    className="wd-block gallery-wrapper"
                    initial={{ y: 20, opacity: 0 }}
                    whileInView={{
                        y: 0,
                        opacity: 1,
                        transition: {
                            duration: 0.4,
                            ease: "circOut",
                        }
                    }}
                    viewport={{
                        once: true,
                        amount: 0.1,
                    }}
                >
                    <div className="wd-media-wrapper">
                        <div className="flickity-controls">
                            <button className="previous" onClick={() => galleryOneRef.current.previous()} aria-label="Previous"></button>
                            <button className="next" onClick={() => galleryOneRef.current.next()} aria-label="Next"></button>
                        </div>

                        <Flickity
                            flickityRef={flkty => galleryOneRef.current = flkty}
                            className={'wd-gallery'}
                            options={galleryOptions}
                            static={true}
                        >
                            <video
                                draggable="false"
                                autoPlay="autoplay"
                                playsInline="playsinline"
                                muted="muted"
                                loop="loop"
                                src={JobsFlowVideo} />

                            <video
                                draggable="false"
                                autoPlay="autoplay"
                                playsInline="playsinline"
                                muted="muted"
                                loop="loop"
                                src={PrimaryNavigationVideo} />

                            <video
                                draggable="false"
                                autoPlay="autoplay"
                                playsInline="playsinline"
                                muted="muted"
                                loop="loop"
                                src={ResumeFlowVideo} />
                        </Flickity>

                        <div className="wd-gallery-caption">
                            <p className="caption-text">
                                {galleryOneCaptions[galleryOneIndex]}
                            </p>
                            <div className="divider"></div>
                            <p className="caption-counter">
                                {galleryOneIndex + 1}/{galleryOneCaptions.length}
                            </p>
                        </div>
                    </div>
                </motion.section>

                {/* U  N  I  -  C  O */}
                <section className="wd-block unico" ref={unicoRef}>
                    <div className="first-column">
                        <motion.div
                            className="wd-cc"
                            variants={fadeVariants}
                            initial="initial"
                            whileInView="animate"
                            viewport={{
                                once: true,
                                root: unicoRef.current,
                                margin: "0% 0% -10% 0%",
                            }}
                        >
                            <div className="title">
                                <h2>Uni Co.</h2>
                                <img src={KeyboardIcon}
                                    alt="⌨️"
                                    className="icon"
                                    loading="eager"
                                    draggable="false" />
                            </div>
                            <div className="copy">
                                <p className="highlight">
                                    The main instrument was ready. For University and Company modules we required a user experience that was familiar to them — the professionals.
                                </p>
                                <p>
                                    <span className="divider"></span>
                                    Using coherent shapes and careful considerations of color, I created a familiar (but not unceremonious) UI system that would work well for both.
                                </p>
                            </div>
                        </motion.div>

                        <motion.div
                            className="wd-cc"
                            variants={fadeVariants}
                            initial="initial"
                            whileInView="animate"
                            viewport={{
                                once: true,
                                root: unicoRef.current,
                                margin: "0% 0% -10% 0%",
                            }}
                        >
                            <div className="copy">
                                <p className="highlight">
                                    I kept the navigation and flows to be so obvious that the UI disappears and the only focus is the task at hand.
                                </p>
                                <p>
                                    <span className="divider"></span>
                                    And that was precisely the brief.
                                </p>
                            </div>
                        </motion.div>
                    </div>
                    <div className="second-column">
                        <div className="wd-media-wrapper">
                            <motion.div
                                className="wd-media"
                                variants={fadeVariants}
                                initial="initial"
                                whileInView="animate"
                                viewport={{
                                    once: true,
                                    root: unicoRef.current,
                                    margin: "0% 0% -10% 0%",
                                }}
                            >
                                <StaticImage
                                    src="../../images/work/connektion/5.jpg"
                                    width={1200}
                                    alt="Wireframe of student section dashboard"
                                    className="wd-media-img"
                                    loading="lazy"
                                    draggable="false"
                                    placeholder="blurred"
                                />
                            </motion.div>
                        </div>
                    </div>
                    <div className="third-column">
                        <motion.div
                            className="wd-cc"
                            variants={fadeVariants}
                            initial="initial"
                            whileInView="animate"
                            viewport={{
                                once: true,
                                root: unicoRef.current,
                                margin: "0% 0% -10% 0%",
                            }}
                        >
                            <div className="copy">
                                <p className="highlight">
                                    Using the time that the client had originally reserved for creating software manual I created a software that wouldn't need any.
                                </p>
                                <p>
                                    <span className="divider"></span>
                                    Complete scope for scalability is retained with a UI system that would still look fresh 5 years later. It's made like an instrument, it works like an instrument (using marvelous ReactJS).
                                    <br /><br />
                                    One would say it <b>is</b> an instrument.
                                </p>
                            </div>
                        </motion.div>
                    </div>
                </section>

                {/* G  A  L  L  E  R  Y    T  W  O */}
                <motion.section
                    className="wd-block gallery-wrapper"
                    initial={{ y: 20, opacity: 0 }}
                    whileInView={{
                        y: 0,
                        opacity: 1,
                        transition: {
                            duration: 0.4,
                            ease: "circOut",
                        }
                    }}
                    viewport={{
                        once: true,
                        amount: 0.1,
                    }}
                >
                    <div className="wd-media-wrapper">
                        <div className="flickity-controls">
                            <button className="previous" onClick={() => galleryTwoRef.current.previous()} aria-label="Previous"></button>
                            <button className="next" onClick={() => galleryTwoRef.current.next()}  aria-label="Next"></button>
                        </div>

                        <Flickity
                            flickityRef={flkty => galleryTwoRef.current = flkty}
                            className={'wd-gallery'}
                            options={galleryOptions}
                            static={true}
                        >
                            <video
                                draggable="false"
                                autoPlay="autoplay"
                                playsInline="playsinline"
                                muted="muted"
                                loop="loop"
                                src={DashboardAndJobsFlowVideo} />

                            <video
                                draggable="false"
                                autoPlay="autoplay"
                                playsInline="playsinline"
                                muted="muted"
                                loop="loop"
                                src={EventsAndRequestsFlowVideo} />
                        </Flickity>

                        <div className="wd-gallery-caption">
                            <p className="caption-text">
                                {galleryTwoCaptions[galleryTwoIndex]}
                            </p>
                            <div className="divider"></div>
                            <p className="caption-counter">
                                {galleryTwoIndex + 1}/{galleryTwoCaptions.length}
                            </p>
                        </div>
                    </div>
                </motion.section>

                {/* O  U  T  R  O */}
                <section className="wd-block outro" ref={outroRef}>
                    <WorkOutro type="software" referrerPath="connektion" />
                </section>

            </article>

            <div className="wd-progress connektion" ref={workProgressBar}>
                <p>
                    <span>{getSplitText(scrollProgress)[0]}</span>{getSplitText(scrollProgress)[1]}
                </p>
            </div>

            <Footer
                furtherReadingLinks={true}
            />

        </Styleswrapper>
    );
}

export default Connektion;
